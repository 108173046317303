export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) return
  if (to.query.diamond) {
    // from shop diamondStep middleware
    if (from.query.step !== to.query.step) {
      let stone

      if (to.query.stoneType == 2) {
        stone = 'gemstone'
      } else if (to.query.diamondType == -3) {
        stone = 'coloured-diamond'
      } else {
        stone = 'diamond'
      }

      ga_event_flow(stone)
    }
  }
})
